@import-normalize;
@import './scss/variables';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

body {
    background: $grey100;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    padding: 0;
    margin: 0;
    
    > * {
        color: $panelText;
        letter-spacing: 1px;
        box-sizing: border-box;
    }
}

label {
    display: block;
    margin-bottom: .5rem;
}

button {
    display: block;
}

h1 {
    font-weight: 300;
}

h2 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1rem;
    margin: 0 0 1rem;
}

.sidebar {
    background: $panel;
    position: fixed;
    height: 100vh;
    left: 0;
    width: 240px;
    padding: 1rem 2rem;
    border-right: 1px solid $grey50;
    transition: .15s ease-out;

    .subtitle {
        color: $grey350;
        font-size: 1rem;
        padding: 0 0 .3rem;
        border-bottom: $border;
    }

    button {
        display: inline-block;
        margin-bottom: 1rem;
        width: 100%;
    }

    .collapseButton {
        position: absolute;
        color: $grey350;
        right: 0;
        top: 0;
        height: 100vh;
        padding: 6.9rem .25rem .25rem;

        .icon {
            transition: .15s ease-out;
            transform: rotate(0deg);
        }

        &:hover {
            background: $grey300;
            color: $gold;
            cursor: pointer;
        }
    }

    &.collapsed {
        left: -280px;

        ~ main {
            margin-left: 2rem;
        }

        .collapseButton .icon {
            transform: rotate(180deg);
        }
    }

    footer {
        color: $grey350;
        text-align: center;
        position: absolute;
        bottom: 4rem;
        left: 0;
        line-height: 1.5;
        width: 100%;
        font-size: 80%;
    
        > span {
            color: red;
        }
    
        > a {
            color: $grey350;
    
            &:hover {
                color: $gold;
            }
        }
    }   
}

main {
    margin-left: calc(240px + 4rem);
    padding: 2rem;
    transition: .15s ease-out;
}

select {
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: #222222;
    border-top: 1px solid $grey50;
    border-right: 1px solid $grey50;
    border-bottom: 1px solid $grey350;
    border-left: 1px solid $grey300;
    border-radius: $borderRadius;
    color: $gold;
    padding: .5rem;
    margin: 0;
    text-align: center;
    width: 100%;

    &:hover {
        background: $grey100;
        cursor: pointer;
    }
}

button {
    background: $grey350;
    color: $panelText;
    display: inline-block;
    font-size: 1rem;
    border-bottom: 1px solid $grey50;
    border-left: 1px solid $grey500;
    border-top: 1px solid $grey500;
    border-right: 1px solid $grey50;
    border-radius: $borderRadius;
    padding: .5rem 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    margin-right: .5rem;

    &:hover {
        background: #E7B791;
        color: $panel;
        cursor: pointer;
    }
}
