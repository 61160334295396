@import '../../scss/variables';

// RESET SLIDERS FOR ALL BROWSERS

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

// NEW CSS

.slider {
  display: inline-block;
  padding: .25rem 0 0;
  text-align: center;
  width: 2.5rem;
  vertical-align: top;

  .sliderSection {
    display: inline-block;
    vertical-align: top;
  }

  label {
    font-size: .5rem;
    letter-spacing: 1px;
    margin-bottom: 0;
    width: 2.5rem;
  }

  input[type=range][orient=vertical] {
    //-webkit-appearance: slider-vertical; /* WebKit */ --> Tricky with custom styling
    background: #222222;
    border-top: 1px solid $grey50;
    border-right: 1px solid $grey50;
    border-bottom: 1px solid $grey350;
    border-left: 1px solid $grey300;
    border-radius: $borderRadius;
    height: 1.5rem;
    width: 7.5rem;
    margin: 3.25rem -3rem;
    transform: rotate(-90deg);
  
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: $panel;
      background: url('./thumb-dot.svg') no-repeat;
      border-top: 1px solid $grey350;
      border-right: 1px solid $grey300;
      border-bottom: 1px solid $grey50;
      border-left: 1px solid $grey50;
      height: 1.375rem;
      width: 1.375rem;
      margin-top: -7px;
      border-radius: $borderRadius;
      cursor: pointer;
    }

    &::-webkit-slider-runnable-track {
      background: black;
      height: .5rem;
      margin: 0 1px;
    }
  }

  output {
    display: block;
    font-size: .5rem;
    letter-spacing: 1px;
  }
  
  .markLabels {
    margin-top: 19px;
  }

  .markLabel {
    font-size: .5rem;
    letter-spacing: 1px;
    padding: 3px 0 4px 0;
    text-transform: uppercase;
    text-align: right;
  }
}

.isToggle {
  width: auto;
  //padding-right: .5rem;
}