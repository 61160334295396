@import '../../scss/variables';

.panel {

    h3 {
        color: $gold;
        font-weight: 300;
    }

    .tabs {
        background: $panel;
        border-top: 1px solid $grey300;
        border-right: 1px solid $grey50;
        border-bottom: 1px solid $grey50;
        border-left: 1px solid $grey350;
        border-radius: $borderRadius;
        padding: 0;
        margin: 0 0 2rem;

        li {
            display: inline-block;
            list-style-type: none;
            padding: 1rem;

            &:hover {
                background: $grey300;
                cursor: pointer;
            }

            &.active {
                color: $grey100;
                background: $gold;

                &:hover {
                    cursor: default;
                }
            }
        }

    }

    .actions {
        margin-bottom: 1rem;
    }

    .sectionGroup {
        display: block;
    }
    
    section {
        background: $panel;
        border-top: 1px solid $grey300;
        border-right: 1px solid $grey50;
        border-bottom: 1px solid $grey50;
        border-left: 1px solid $grey350;
        border-radius: $borderRadius;
        display: inline-block;
        margin-bottom: 1rem;
        padding: .5rem 1rem;
        text-align: center;
        vertical-align: top;
        min-height: 13rem;
        width: auto;
    
        > h2 {
            text-align: center;
        }
    
        > .subSection {
            display: block;
            height: 6.5rem;
        }

        > .subSectionFill {
            display: block;
            height: 13rem;
        }

        > .subSectionExtra {
            display: block;
            height: 19.5rem;
        }
    }    
}

